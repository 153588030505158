import store from '@/store/index.js'
const router_domain_url = store.state.router_domain_url

// 异步请求处理
/**
 *
 * @param {路径} path
 * @param {组件路径} file
 * @param {别名，没有传null } name
 * @param {说明，没有就传{} } meta
 * @param {子级，没有子级可以不传} children
 */
function route(path, file, name, meta, children, redirect) {
  return {
    exact: true,
    path,
    name,
    meta,
    children,
    redirect,
    component: () => import(`@/views/customization/${router_domain_url}/pages/${file}`)
  }
}
const indexRouterlist = [
    route('/meiluGoodDetail', 'home/meiluGoodDetail', 'meiluGoodDetail', { title: '煤炉详情', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
    route('/goodDetail', 'home/goodDetail', 'goodDetail', { title: '煤炉详情', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
    route('/fill-shop', 'fillshop/index', '', { title: '填单购物', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: true }),
]
const personRouterlist = [
  route('/After-sales', 'Orders/After-sales', '', { title: '雅虎-竞拍中', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
]
export default {
  indexRouterlist: indexRouterlist,
  personRouterlist:personRouterlist,
}
