import Ajax from "@/api/ajax";
export default{
    // 首页轮播图
    homeBanner(params) {
        return Ajax.get('/Home/get_home_set_list', params)
    },
    webmsg(params) {
        return Ajax.get('/Home/webMsg', params)
    },
    // 海淘话题详情
    topicDetail(params) {
        return Ajax.get('/Home/get_topic_detail', params)
    },
    // 煤炉留言模版获取
    commentTemplate(params) {
        return Ajax.post('/Home/commentTemplate', params)
    },
    // 煤炉提交留言
    submitComment(params) {
        return Ajax.post('/Home/submitComment', params)
    },
}