// 定义域名分组
const domainGroups = {
  other: ['jpweb.bid.fans', 'esenmart.com','jpnbuy.com','www.jpnbuy.com'],
  xg: ['gsritao.com', 'www.gsritao.com', 'riya.xin', 'wakuwaku.bar', 'ripaigo.com', 'croxx.zzqss.com', 'wanshiwu.jp', 'renkongjian.zzqss.com'],
  xgTwo: ['jpxu.zzqss.com', 'jpware.com.hk', 'japan51buy.zzqss.com', 'meilu.jp', 'www.meilu.jp','japan51buy.com'],
  xgThree: ['gobuy.jp', 'yueyanggou.net', 'jp.habigoujp.com', 'eshoppingtokyo.com', 'jp.moximoxijp.com', 'ezbidbuy.com', 'habigoujp.com', 'www.yueyanggou.net', 'jpweb.zzqss.com','beijixing.zzqss.com', 'beijixingbuy.com','japan51buy.com'],
  ripaigo: ['ripaigo.com', 'ripaigo.zzqss.com'],
  gobuy: ['gobuy.zzqss.com'],
};

export default domainGroups
