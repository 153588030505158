import Ajax from "@/api/ajax";
export default {
    //获取雅虎出价账号
    getBidNumber(params) {
        return Ajax.post("/Home/bidNumbers", params);
    },
    // 提交发货
    createPack(params) {
        return Ajax.post("/Home/createPackageHk", params);
    },
    // 下载invoic文件 Home/invoiceOrderMsg
    invoiceOrderMsg(params) {
        return Ajax.post("/Home/invoiceOrderMsg", params);
    }
}