import store from '@/store/index.js'
const router_domain_url = store.state.router_domain_url

// 异步请求处理
/**
 *
 * @param {路径} path
 * @param {组件路径} file
 * @param {别名，没有传null } name
 * @param {说明，没有就传{} } meta
 * @param {子级，没有子级可以不传} children
 */
function route(path, file, name, meta, children, redirect) {
  return {
    exact: true,
    path,
    name,
    meta,
    children,
    redirect,
    component: () => import(`@/views/customization/${router_domain_url}/pages/${file}`)
  }
}
const publicRouter = [
    route('/signup-Center', 'public/signupCenter', 'signupCenter', { title: '注册成功', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: false }),
]

const publicRouterlist = [
  route('/sign-up', 'public/SignUp', 'signUp', { title: '注册', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
]

export default {
    publicRouter: publicRouter,
    publicRouterlist: publicRouterlist,
}
