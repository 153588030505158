import store from '@/store/index.js'
const router_domain_url = store.state.router_domain_url

// 异步请求处理
/**
 *
 * @param {路径} path
 * @param {组件路径} file
 * @param {别名，没有传null } name
 * @param {说明，没有就传{} } meta
 * @param {子级，没有子级可以不传} children
 */
function route(path, file, name, meta, children, redirect) {
  return {
    exact: true,
    path,
    name,
    meta,
    children,
    redirect,
    component: () => import(`@/views/customization/${router_domain_url}/pages/${file}`)
  }
}
const indexRouterlist = [
  route('/', 'yahoojp/homeIndex', 'homeIndex', { title: '首页', ssr: true, pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/yahoohome', 'yahoojp/Home', 'home', { title: '雅虎竞拍', ssr: true, pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/goodsList', 'yahoojp/goodsList', 'goodsList', { title: '雅虎商品列表', ssr: true, pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/order-pack-pay', 'Orders/OrderpackPay', 'OrderpackPay', { title: '订单打包', ssr: true, pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/meiluHome', 'mercari/meiluHome', 'meiluHome', { title: '煤炉首页', ssr: true, pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/goodDetail', 'yahoojp/goodDetail', 'YahoojpDetail', { title: '商品详情', ssr: true, pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/checkout-alternative', 'shop/CheckoutAlternative', 'CheckoutAlternative', { title: '提交订单', ssr: true, pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/order-pay', 'Orders/orederPay', 'orederPay', { title: '支付订单', ssr: true, pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/for-pack-pay', 'Orders/ForPackPay', 'ForPackPay', { title: '支付国际运费', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
]
const personRouterlist = [
  route('/add-address', 'address/AddAddress', '', { title: '添加地址', ssr: true, pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/card-management', 'cardManagement/cardManage', '', { title: '银行卡管理', ssr: true, pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/top-up', 'user/topUp', '', { title: '账户充值', ssr: true, pageWidth: true, keepAlive: false, requireAuth: true })
]

const publicRouterlist = [
  route('/forget-pwd', 'public/ForgetPwd', 'forgetPwd', { title: '忘记密码', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: false }),
  route('/sign-up', 'public/SignUp', 'signUp', { title: '注册', ssr: true, pageWidth: true, keepAlive: false, requireAuth: false }),
]

export default {
  indexRouterlist: indexRouterlist,
  personRouterlist: personRouterlist,
  publicRouterlist: publicRouterlist,
}
