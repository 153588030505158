import Ajax from "@/api/ajax";
export default{
    //测试
    bigShop_add(params) {
        return Ajax.post("/Home/get_web_navigation", params);
    },
    buyFeeBaoYue(params) { //购买转账手续费包月
        return Ajax.post('/Home/buyTransfeeBaoYue', params);
      }

}