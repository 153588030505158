import Ajax from "@/api/ajax";
export default{
    //测试
    bigShop_add(params) {
        return Ajax.post("/Home/get_web_navigation", params);
    },
    // 代收(预报下单)
    prediction_shop(params) {
        return Ajax.post("/Home/prediction_shop", params);
    },
    // 设置出价被超越是否发送邮件
    setEmailOverBid(params) {
        return Ajax.post("/Home/setEmailOverBid", params);
    },
    // 自定义站点文章
    pageTemplate(params) {
        return Ajax.post("/Home/pageTemplate", params);
    },

}