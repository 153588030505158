import Ajax from "@/api/ajax";
export default{
    //发起提现申请
    add_cash(params) {
        return Ajax.post("/Home/add_cash", params);
    },
    // 提现列表
    get_cash_list(params) {
        return Ajax.post("/Home/get_cash_list", params);
    },
    // 提现列表
    smsMsg(params) {
        return Ajax.post("/Home/smsMsg", params);
    },

}