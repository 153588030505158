<template>
  <div>
    <transition name="fade">
      <v-snackbar
        :timeout="-1"
        :value="true"
        absolute
        :color="color"
        centered
        v-if="isshow"
        class="fixed_"
      >
        {{ content }}
      </v-snackbar></transition
    >
  </div>
</template>
<script>
export default {
  props: {
    content: {
      type: String,
      default: "",
    },
    //失败   #d23f57;
    type: {
      type: String,
      default: "success",
    },
    duration: {
      type: Number,
      default: 2000,
    },
  },
  data() {
    return {
      isshow: false,
      alerttimer: null,
      colorlist: {
        success: "#009230",
        error: "#d23f57",
        wraning: "#f57c00",
        info: "#607d8b",
      },
      color:'#009230'
    };
    
  },
  created(){
     this.color=this.colorlist[this.type]
  },
  methods: {
    show() {
      this.isshow = true;
      let that = this;
      setTimeout(() => {
        that.hide();
      }, this.duration);
    },
    hide() {
      this.isshow = false;
      this.remove();
    },
  },
};
</script>
<style scoped>
  .fixed_{
    position: fixed;
    z-index: 99999;
  }
</style>