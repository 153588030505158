import store from '@/store/index.js'
const router_domain_url = store.state.router_domain_url

// 异步请求处理
/**
 *
 * @param {路径} path
 * @param {组件路径} file
 * @param {别名，没有传null } name
 * @param {说明，没有就传{} } meta
 * @param {子级，没有子级可以不传} children
 */
function route(path, file, name, meta, children, redirect) {
  return {
    exact: true,
    path,
    name,
    meta,
    children,
    redirect,
    component: () => import(`@/views/customization/${router_domain_url}/pages/${file}`)
  }
}
const indexRouterlist = [
  route('/order-pack-pay', 'Orders/OrderpackPay', 'OrderpackPay', { title: '订单打包', ssr: true, pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/AUCNET', 'AUCNET/list', 'AUCNET', { title: 'auc会场列表', ssr: true, pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/AUCNET-Tuesday', 'AUCNET/list-tuesday', 'AUCNET-Tuesday', { title: 'auc综合会场', ssr: true, pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/AUCNET-detail', 'AUCNET/detail', 'AUCNET-detail', { title: 'auc会场详情', ssr: true, pageWidth: true, keepAlive: true, requireAuth: true }),
  route('/AUCNET-Tuesday-detail', 'AUCNET/detail-tuesday', 'AUCNET/detail-tuesday', { title: 'auc综合会场详情', ssr: true, pageWidth: true, keepAlive: true, requireAuth: true }),
]
const personRouterlist = [
  route('/order-list', 'Orders/OrderList', 'OrderList', { title: '代购订单列表', ssr: true, pageWidth: true, keepAlive: false, requireAuth: true }),
]

export default {
  indexRouterlist: indexRouterlist,
  personRouterlist: personRouterlist,
}
