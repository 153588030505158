import store from '@/store/index.js'
const router_domain_url = store.state.router_domain_url

// 异步请求处理
/**
 *
 * @param {路径} path
 * @param {组件路径} file
 * @param {别名，没有传null } name
 * @param {说明，没有就传{} } meta
 * @param {子级，没有子级可以不传} children
 */
function route(path, file, name, meta, children, redirect) {
  return {
    exact: true,
    path,
    name,
    meta,
    children,
    redirect,
    component: () => import(`@/views/customization/${router_domain_url}/pages/${file}`)
  }
}

const indexRouterlist = [
  route('/', 'home/Index', 'HomeIndex', { title: '首页', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/yahoojp', 'yahoojp/Index', 'YahoojpIndex', { title: '雅虎拍', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/goodDetail', 'yahoojp/goodDetail', 'YahoojpDetail', { title: '商品详情', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/meiluGoodDetail', 'mercari/meiluGoodDetail', 'MercariDetail', { title: '商品详情', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/rakutenGoodDetail', 'Rakuten/rakutenGoodDetail', 'RakutenDetail', { title: '商品详情', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/RakutenerGoodDetail', 'rakuten_old/RakutenerGoodDetail', 'RakutenOldDetail',{ title: '商品详情', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/paypayGoodDetail', 'paypay/paypayGoodDetail', 'PaypayDetail',{ title: '商品详情', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/zozoGoodDetail', 'zozotown/zozoGoodDetail', 'ZozotownDetail',{ title: '商品详情', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/SurugaGoodList', 'Suruga/SurugaGoodList', 'SurugaDetail',{ title: '商品详情', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/mikihouseDetails', 'mikihouse/mikihouseDetails', 'MikihouseDetail',{ title: '商品详情', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/amazonGoodDetail', 'amazon/amazonGoodDetail', 'AmazonDetail',{ title: '商品详情', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/selfGoodDetail', 'selfShopping/selfGoodDetail', '', { title: '自营商城详情', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: true }),
  route('/articleInfo', 'home/articleinfo', '', { title: '海淘话题详情', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: true }),
]
const personRouterlist=[
  route('/order-list', 'Orders/OrderList', 'OrderList', { title: '代购订单列表', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/order-pack', 'Orders/Orderpack', '', { title: '订单待打包', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/order-details', 'Orders/OrderDetails', 'OrderDetails', { title: '代购订单详情', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/order-list-pack', 'Orders/OrderListPack', 'OrderListPack', { title: '转运包裹', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/order-pack-details', 'Orders/OrderPackDetails', 'OrderPackDetails', { title: '转运包裹详情', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/AUCNET-orderlist', 'channel/AUCNET/orderlist', '', { title: 'auc订单列表', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/AUCNET-bondlist', 'channel/AUCNET/bondlist', '', { title: 'auc保证金', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
]
export default {
  indexRouterlist: indexRouterlist,
  personRouterlist:personRouterlist,
}