import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store/index'
Vue.use(VueI18n)
function getI18n() {
  const langList = store.state.langJson?store.state.langJson:{};
  const langSign = store.state.statedeflang?store.state.statedeflang.lang_sign:"zh";
  const messages = {}
  messages[langSign]=langList
  // 翻译
  const i18n = new VueI18n({
    locale: langSign,
    messages:messages,
    silentTranslationWarn: true // 注释警告
  })
  //console.log(i18n)
  return i18n
}
export default {
  getI18n
}