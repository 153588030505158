import Ajax from "@/api/ajax";
export default{
    //返利记录
    getrebateRecord(params) {
        return Ajax.post("/Home/rebate_record", params);
    },
    //我的推广
    getMyPromotion(params) {
        return Ajax.post("/Home/my_promotion", params);
    },
    //返利提现
    rebateCash(params) {
        return Ajax.post("/Home/rebate_cash", params);
    },
    

}