import Ajax from "@/api/ajax";
export default{
    // 区号列表
    smsMsg(params) {
        return Ajax.post("/Home/smsMsg", params);
    },
    // 提交绑定信用卡
    bindingPayCard(params) {
        return Ajax.post("/Home/bindingPayCard", params);
    },
    // 信用卡列表
    getUserPayCard(params) {
        return Ajax.post("/Home/getUserPayCard", params);
    },
    // 删除绑定信用卡
    deleteUserPayCard(params) {
        return Ajax.post("/Home/deleteUserPayCard", params);
    },
    // 获取竞拍支付限额
    getPayMone(params) {
        return Ajax.post("/Home/checkUserMoney", params);
    }
}