//注册Toast实例
import Vue from "vue";

export default function createToast(Component, props) {
  const ToastCons = Vue.extend(Component);
  const instance = new ToastCons({propsData:props});
  instance.$mount();
  document.getElementById('app').appendChild(instance.$el)
  instance.remove=()=>{
    document.getElementById('app').removeChild(instance.$el)
      instance.$destroy();
  }
  return instance;
}
